/*import logo from './logo.svg';*/
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <p>
          Welcome to the home page for Neural Blue Ltd.
        </p>
        <a
          className="App-link"
          href="https://neural.blue"
          target="_blank"
          rel="noopener noreferrer"
        >
          Neural Blue Ltd
        </a>
      </header>
      <body>
        <div>
          <footer className="App-footer"><h6>
          Neural Blue Ltd is registered in England and Wales with company number 06040557
          </h6></footer>
        </div>
      </body>
    </div>
  );
}

export default App;
